@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@import "~glightbox/dist/css/glightbox.min.css";

*,
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Lato", Helvetica, Sans-Serif;
}
